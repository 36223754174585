import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Slider from 'react-slick'
import ImageWrap from 'components/ImageWrap'
import { projectsStandardSort } from 'utils/functions'

const RPTitle = (props) => {
  const tr = props.tr

  return (
    <header className="title-subtitle">
      <div className="small-caps title">
        <LinkWrap to="/bespoke/projects">
          {props.sourcePage === 'project'
            ? tr('RELATED_PROJECTS')
            : tr('PROJECTS')}
        </LinkWrap>
        {props.sourcePage === 'collection' || props.sourcePage === 'product' ? (
          <div className="sub-title">
            {tr('FEATURING_PRODUCTS_IN_COLLECTION')}
          </div>
        ) : (
          ''
        )}
      </div>
    </header>
  )
}

const Slide = ({ project }) => {
  return (
    <div className="item-grid item-black-white">
      <LinkWrap to={`/bespoke/projects/${project.slug}`}>
        <div className="img-wrapper-container">
          <div className="img-wrapper-proportion">
            <ImageWrap image={project.image} />
          </div>
        </div>
      </LinkWrap>
      <ul className="caption">
        <li className="small-caps location">{project.location.title}</li>
        <li className="title">
          <h4>
            <LinkWrap to={`/bespoke/projects/${project.slug}`} className="dark">
              {project.title}
            </LinkWrap>
          </h4>
        </li>
      </ul>
    </div>
  )
}

const RelatedProjectsSlideshow = ({ projects, sourcePage, tr }) => {
  projects = projectsStandardSort(projects)
  projects = projects.slice(0, 7)

  const settings = {
    dots: true,
    fade: false,
    cssEase: 'ease-out',
    autoplay: false,
    arrows: false,
    //variableWidth: true,
    centerMode: false,
    slidesToShow: 7, // large-desktop (1680 )
    slideToScroll: 1,
    infinite: false,
    draggable: true,
    responsive: [
      {
        breakpoint: 1280, // small-desktop ( 1280 )
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024, // desktop and tablet ( below 1024 )
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768, // desktop and tablet ( below 1024 )
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480, // mobile ( below 480 )
        settings: {
          //centerMode: true,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className="single-project">
      {!(sourcePage === 'collection' || sourcePage === 'product') && (
        <div className="line line-sep" />
      )}

      <div className="wrap related-projects-wrap">
        <div className="related-projects-container">
          <div className="related-projects">
            <RPTitle sourcePage={sourcePage} tr={tr} />
            <div className="projects-slider-container">
              {
                <Slider {...settings} className="projects-slider">
                  {projects.map((project, i) => (
                    <Slide key={i} project={project} />
                  ))}
                </Slider>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RelatedProjectsSlideshow
