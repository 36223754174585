import React, { Component } from 'react'
import LinkWrap from 'components/LinkWrap'
import Layout from 'components/layout'
import Pagination from 'components/pagination'
import ImageWrap from 'components/ImageWrap'
import PaddedSlideshow from 'components/slideshows/paddedSlideshow'
import RelatedProjectsSlideshow from 'components/slideshows/relatedProjectsSlideshow'
import Title from 'components/title'
import { projectsStandardSort, trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

// layout: image size, centering

const FeaturingCollections = ({ collections, tr }) => {
  const translationItems = {
    featuring: tr('FEATURING'),
    comma: tr('COMMA'),
    space: tr('SPACE'),
    collections: tr('COLLECTIONS'),
    collection: tr('COLLECTION'),
  }

  return (
    <p>
      {translationItems.featuring + translationItems.space}
      {collections.map((collection, i) => {
        const output = (
          <span key={i}>
            <LinkWrap to={`/collections/collections/${collection.slug}`}>
              {collection.title}
            </LinkWrap>
            {i === collections.length - 1
              ? ''
              : translationItems.comma + translationItems.space}
          </span>
        )
        return output
      })}
      {translationItems.space +
        (collections.length > 1
          ? translationItems.collections
          : translationItems.collection)}
    </p>
  )
}

class Project extends Component {
  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const allProjects = projectsStandardSort(this.props.data.projects.edges)
    const thisProject = this.props.data.thisProject
    const thisPage = this.props.data.thisPage

    let allRelatedProjects = []
    if (thisProject.designer) {
      const projectDesignerProjects = thisProject.designer.projects
      projectDesignerProjects?.forEach((project) => {
        allRelatedProjects.push(project)
      })
    }

    if (thisProject.collections) {
      const projectCollectionsProjects = thisProject.collections.map(
        (collection) => collection.projects,
      )
      projectCollectionsProjects.forEach((projectArray) => {
        projectArray?.forEach((project) => {
          const match = allRelatedProjects.filter(
            (allProject) => allProject.id === project.id,
          ) // check if project is already in array
          if (match.length === 0) {
            allRelatedProjects.push(project)
          }
        })
      })
    }

    return (
      <Layout>
        <div id="content">
          <Title
            subtitle={thisPage.title}
            subtitleLink="/bespoke/projects"
            title={thisProject.title}
          />
          {thisProject.slides && (
            <PaddedSlideshow slides={thisProject.slides} />
          )}
          <section className="page-content wrap">
            <div>
              <div
                className="small-caps date header-date"
                style={{ textAlign: 'center' }}
              >
                {thisProject.location.title}
              </div>
              <div className="wrap-text-large centered strip-p single-commons">
                {thisProject.collections && (
                  <FeaturingCollections
                    collections={thisProject.collections}
                    tr={tr}
                  />
                )}
                {thisProject.content && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: thisProject.content.childMarkdownRemark.html,
                    }}
                  />
                )}
              </div>
            </div>
            {thisProject.designer && (
              <div className="wrap-designer-grid">
                <div className="product-designer" style={{ width: '100%' }}>
                  <div className="col">
                    <LinkWrap
                      to={`/collections/designers/${thisProject.designer.slug}`}
                    >
                      <ImageWrap image={thisProject.designer.image} />
                    </LinkWrap>
                  </div>
                  <div className="col">
                    <div className="txt">
                      <div className="small-caps max-12">
                        {tr('DESIGNED_BY')}
                      </div>
                      <h1>
                        <LinkWrap
                          to={`/collections/designers/${thisProject.designer.slug}`}
                          className="dark"
                        >
                          {thisProject.designer.title}
                        </LinkWrap>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {allRelatedProjects.length > 0 && (
              <RelatedProjectsSlideshow
                projects={allRelatedProjects}
                sourcePage="project"
                tr={tr}
              />
            )}
            <Pagination
              slug="bespoke/projects"
              list={allProjects}
              item={thisProject}
              label={tr('PROJECT')}
              data={this.props.data}
            />
          </section>
        </div>
      </Layout>
    )
  }
}
export default Project

export const projectQuery = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisProject: contentfulProjects(slug: { eq: $slug }) {
      id
      title
      slug
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        ...GatsbyImage
      }
      location {
        title
      }

      slides {
        images {
          ...GatsbyImage
        }
        caption
      }
      collections {
        id
        title
        slug
        projects {
          id
          title
          slug
          isFeatured
          datePosted
          location {
            id
            title
          }
          image {
            ...GatsbyImage
          }
        }
      }
      designer {
        title
        slug
        image {
          ...GatsbyImage
        }
        projects {
          id
          title
          slug
          isFeatured
          datePosted
          location {
            id
            title
          }
          image {
            ...GatsbyImage
          }
        }
      }
    }
    projects: allContentfulProjects(
      sort: { fields: [datePosted], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          datePosted
          isFeatured
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "projects" }) {
      title
      slug
    }
  }
`
